import { ImageField, ImageFieldClasses } from 'react-admin'
import { styled } from '@mui/material/styles'

const SmallImageField = styled(ImageField)(({ theme }) => ({
  [`& .${ImageFieldClasses.image}`]: {
    width: 32,
    height: 48,
    margin: 0,
    objectFit: 'cover',
  },
}))

export default SmallImageField