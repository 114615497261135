import { fetchUtils } from 'ra-core'
import { AuthStorage, AuthKey } from './authStorage'

// copied from ra-core/src/dataProvider/fetch.ts
interface Options extends RequestInit {
  user?: {
      authenticated?: boolean;
      token?: string;
  }
}

type HttpClientResponse = Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}>

const createHttpClient = (authStorage: AuthStorage) => (url: any, options: Options = {}): HttpClientResponse => {
  const headers = options.headers = new Headers(options.headers)
  headers.set('Accept', 'application/json')
  headers.set('Content-Type', 'application/json')
  //headers.set('X-Api-Feature-Indexed-Arrays', '1')
  //headers.set('X-Api-Feature-Shallow-Serialization', '1')
  const token = authStorage.get(AuthKey.TOKEN);
  if (token) {
    options.user = { authenticated: true, token: `Bearer ${token}` }
  }
  return fetchUtils.fetchJson(url, options);
}

export default createHttpClient