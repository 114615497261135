import { AuthProvider, fetchUtils } from 'ra-core' // resolves to wrong interface
import { AuthStorage, AuthKey } from './authStorage'
import { Role } from '../models/types'
import { hasRole, PermissionCheck } from './permission'

interface AuthParams {
  username: string,
  password: string,
}

const createAuthProvider = (entrypoint: string, authStorage: AuthStorage, httpClient = fetchUtils.fetchJson): AuthProvider => ({
  login: async ({username, password}: AuthParams): Promise<any> => {
    try {
      const options = {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      }
      const { json, status } = await httpClient(`${entrypoint}/login.json`, options)
      
      if(status !== 200) throw new Error('Bad email, password or permissions')

      const { token } = json
      authStorage.set(AuthKey.UID, '1');
      authStorage.set(AuthKey.TOKEN, token);
      authStorage.set(AuthKey.ROLES, [Role.ROLE_USER]);
    } catch(e) {
      return Promise.reject(e)
    }
    return Promise.resolve();
  },
  
  logout: (): Promise<void | string> => {
    authStorage.clear()
    return Promise.resolve()
  },

  checkError: (e): Promise<void> => {
    return Promise.resolve()
  },

  checkAuth: (): Promise<void> => {
    return authStorage.get(AuthKey.UID) ? Promise.resolve() : Promise.reject()
  },

  getPermissions: (): Promise<PermissionCheck> => {
    const checker: PermissionCheck = (role: Role): boolean => {
      const roles: Role[] = authStorage.get(AuthKey.ROLES) || []
      return hasRole(roles, role)
    }
    return Promise.resolve(checker)
  }
})


export default createAuthProvider
