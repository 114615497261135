import React from 'react'
import { Show, SimpleShowLayout, TextField, NumberField, ShowProps } from 'react-admin'
import { DefaultShowActions } from '../../components/form'

const AllegroOrderShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show
      actions={<DefaultShowActions/>}
      {...props}
    >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="buyer" />
      <NumberField source="total" options={{style: 'currency', currency: 'PLN'}} />
      <TextField source="fulfillment" />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
}

export default AllegroOrderShow