import React from 'react'
import { Show, SimpleShowLayout, TextField, NumberField, ShowProps, TitleProps, ChipField, useRecordContext } from 'react-admin'
import { MediumImageField } from '../../components/field'
import { DefaultShowActions } from '../../components/form'

const WooProductShowTitle: React.FC<TitleProps> = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>{record ? `${record.id} [${record.sku}]  ${record.name}` : ''}</span>;
}

const WooProductShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show
      title={<WooProductShowTitle />}
      actions={<DefaultShowActions/>}
      {...props}
    >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="sku" />
      <TextField source="name" />
      <ChipField source="type" />
      <ChipField source="status" />
      <NumberField source="available" />
      <NumberField source="price" options={{style: 'currency', currency: 'PLN'}} />
      <MediumImageField source="image" />
    </SimpleShowLayout>
  </Show>
}

export default WooProductShow