import React from 'react'
import { List, Datagrid, TextField, ListProps, ShowButton, EditButton } from 'react-admin'

const IntegrationList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Integrations" perPage={25} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="name" />
      <TextField source="urlPattern" />
      <>
        <ShowButton label="Show" />
        <EditButton label="Edit" />
      </>
    </Datagrid>
  </List>
}

export default IntegrationList
