export interface AuthStorage {
  get(key: string): any
  set(key: string, val: any): void
  clear(): void
}

export enum AuthKey {
  TOKEN = 'TOKEN',
  UID = 'UID',
  ROLES = 'ROLES',
}

export const createAuthStorage = (storage: Storage = window.sessionStorage): AuthStorage => {
  if(!storage) throw new Error('No storage provided')
  return {
    get: (key: string): any => {
      const val = storage.getItem(key)
      if(val === null) return null
      return JSON.parse(val)
    },
    set: (key: string, val: any): void => storage.setItem(key, JSON.stringify(val)),
    clear: () => storage.clear()
  }
}
