import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Link, Title } from 'react-admin';

const Dashboard: React.FC<{}> = () => (
  <Card>
    <Title title="Dashboard" />
    <CardHeader title="Dashboard"/>
    <CardContent>
      Welcome Shop Sync App
      <hr/>
      <Link to="/compare">Compare offers</Link> | <Link to="/sync">Sync</Link>
    </CardContent>
  </Card>
)

export default Dashboard