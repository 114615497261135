import React from 'react'
import { List, Datagrid, TextField, NumberField, TextInput, ListProps, ShowButton } from 'react-admin'

const filters = [
  <TextInput label="Buyer" source="buyer" alwaysOn resettable />
];

const AllegroOrderList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Allegro Orders" perPage={25} filters={filters} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="buyer" />
      <NumberField source="total" options={{style: 'currency', currency: 'PLN'}} />
      <TextField source="fulfillment" />
      <TextField source="status" />
      <>
        <ShowButton label="Show" />
      </>
    </Datagrid>
  </List>
}

export default AllegroOrderList
