import React from 'react'
import { Show, TextField, ShowProps, ReferenceField, NumberField, ReferenceManyField, Datagrid, DateField, FunctionField, TabbedShowLayout, Tab, SelectField, ShowButton } from 'react-admin'
import { MediumImageField } from '../../components/field'
import { DefaultShowActions } from '../../components/form'
import { statusChoices, typeChoices } from './list'

const formatChanges = (record: any) => {
  return <ul>
    {Object
      .keys(record.changes)
      .map((key: string) => <li key={record.id + '_' + key}><b>{`${key}`}</b>: {`${record.changes[key][0]} => ${record.changes[key][1]}`}</li>)
    }
  </ul>
}
// https://marmelab.com/react-admin/ShowTutorial.html#building-a-custom-layout
// https://stackoverflow.com/questions/50992548/how-do-you-conditionally-show-fields-in-show-component-in-react-admin
const OfferShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show actions={<DefaultShowActions showDelete />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="sku" />
        <TextField source="name" />
        <SelectField source="type" choices={typeChoices}/>
        <SelectField source="status" choices={statusChoices}/>
        <ReferenceField label="Parent" source="parent.id" reference="offers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Integration" source="integration.id" reference="integrations" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="quantity" />
        <NumberField source="price"/>
        <FunctionField source="offerId" render={ (record: any) => record.url ? <a href={`${record.url}`} rel="noreferrer" target="_blank">{`${record.offerId}`}</a> : <span>{`${record.offerId}`}</span> } />
        <TextField source="offerVariantId" label="Variant ID" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <MediumImageField source="image" />
      </Tab>
      <Tab label="Variants">
        <ReferenceManyField reference="offers" target="parent" label="Offer Variants">
          <Datagrid>
            <TextField source="id" />
            <TextField source="sku" />
            <TextField source="name" />
            <TextField source="quantity" />
            <TextField source="price" />
            <ShowButton/>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Offer Audit">
        <ReferenceManyField reference="offer_audits" target="offer" label="Offer Changes">
          <Datagrid empty={<p>"No records"</p>}>
            <TextField source="id" />
            <DateField source="createdAt" showTime/>
            <FunctionField
              source="changes"
              render={(record: any) => formatChanges(record)}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
}

export default OfferShow