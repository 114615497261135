import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, SelectInput } from 'react-admin'
import { INTEGRATION_TYPE_CHOICES } from '../../models/types'
import { DefaultEditActions } from '../../components/form'

const IntegrationEdit: React.FC<EditProps> = ({ ...props }) => {
  return <Edit mutationMode="pessimistic" redirect="show" actions={<DefaultEditActions/>} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput source="type" choices={INTEGRATION_TYPE_CHOICES}/>
      <TextInput source="name" />
      <TextInput source="urlPattern" />
      <TextInput source="credentials" multiline fullWidth />
    </SimpleForm>
  </Edit>
}

export default IntegrationEdit