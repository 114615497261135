import React from 'react'
import { List, Datagrid, TextField, NumberField, TextInput, SelectInput, ListProps, ChipField, ShowButton } from 'react-admin'
import { SmallImageField } from '../../components/field'

const filters = [
  <TextInput label="Name" source="name" alwaysOn resettable />,
  <TextInput label="Sku" source="sku" alwaysOn resettable />,
  <SelectInput label="Status" source="status" alwaysOn resettable choices={[
    { id: 'ACTIVE', name: 'Active' },
    { id: 'INACTIVE', name: 'Inactive' },
    { id: 'ACTIVATING', name: 'Activating' },
    { id: 'ENDED', name: 'Ended' },
  ]} />,
  <SelectInput label="Type" source="type" alwaysOn resettable choices={[
    { id: 'BUY_NOW', name: 'Buy Now' },
    { id: 'AUCTION', name: 'Auction' },
    { id: 'ADVERTISEMENT', name: 'Advertisement' },
  ]} />,
];

const AllegroOfferList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Allegro Offers" perPage={25} filters={filters} filterDefaultValues={{status: 'ACTIVE'}} {...props}>
    <Datagrid>
      <SmallImageField source="image" />
      <TextField source="id" />
      <TextField source="sku" />
      <TextField source="name" />
      <NumberField source="available" />
      <NumberField source="price" options={{style: 'currency', currency: 'PLN'}} />
      <ChipField source="type" />
      <ChipField source="status" />
      <>
        <ShowButton label="Show" />
      </>
    </Datagrid>
  </List>
}

export default AllegroOfferList
