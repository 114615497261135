import React from 'react'
import { Create, CreateProps, NumberInput, SimpleForm, TextInput } from 'react-admin'
import { DefaultCreateActions } from '../../components/form'

const CrawlCheckCreate: React.FC<CreateProps> = ({ ...props }) => {
  return <Create redirect="show" actions={<DefaultCreateActions/>} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" />
      <NumberInput source="status" step={1} min={0} />
      <TextInput source="expression" multiline fullWidth />
    </SimpleForm>
  </Create>
}

export default CrawlCheckCreate