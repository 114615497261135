import React, { useState } from "react"
import { Title, useCreate, Button, useNotify } from 'react-admin'
import { CardHeader, CardContent, Card, Stack } from '@mui/material'

const Compare: React.FC<{}> = () => {
  const notify = useNotify()
  const [result, setResult] = useState<string | null>(null)

  const onSuccess = (data: any) => {
    console.log(data)
    setResult(JSON.stringify(data, null, 2))
    notify('Compare done')
  }
  const onError = (error: any) => {
    console.log(error)
    setResult(JSON.stringify(error, null, 2))
    notify(`Compare error: ${error.message}`, { type: 'warning' });
  }

  const [create, { isLoading }] = useCreate('offers-report', {}, {
    onSuccess: onSuccess,
    onError: onError,
  })

  return <Card>
    <Title title="Compare" />
    <CardHeader title="Compare" />
    <CardContent>

      <Stack direction="row-reverse" spacing={2}>
        <Button label="Populate" onClick={() => create()} disabled={isLoading} />
      </Stack>

      <pre>{result}</pre>

    </CardContent>
  </Card>
}

export default Compare