import React from 'react'
import { List, Datagrid, TextField, ListProps, ShowButton, EditButton } from 'react-admin'

const CrawlCheckList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Crawl checks" perPage={25} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <TextField source="status" />
      <>
        <ShowButton label="Show" />
        <EditButton label="Edit" />
      </>
    </Datagrid>
  </List>
}

export default CrawlCheckList
