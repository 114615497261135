import React from 'react'
import { TopToolbar, ListButton, CreateActionsProps } from 'react-admin'
import { Grid } from '@mui/material'

const DefaultCreateActions: React.FC<CreateActionsProps> = () => (
  <TopToolbar>
    <Grid container>
      <Grid item xs={4}>
          <ListButton label="Back to List"/>
      </Grid>
      <Grid item xs={8} display="flex" justifyContent="flex-end">
      </Grid>
    </Grid>
  </TopToolbar>
)

export default DefaultCreateActions