import React from 'react'
import { Edit, EditProps, NumberInput, SimpleForm, TextInput } from 'react-admin'
import { DefaultEditActions } from '../../components/form'

const CrawlCheckEdit: React.FC<EditProps> = ({ ...props }) => {
  return <Edit mutationMode="pessimistic" redirect="show" actions={<DefaultEditActions/>} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="url" />
      <NumberInput source="status" step={1} min={0} />
      <TextInput source="expression" multiline fullWidth />
    </SimpleForm>
  </Edit>
}

export default CrawlCheckEdit