import React from 'react'
import { Show, SimpleShowLayout, TextField, EditButton, ListButton, TopToolbar, Button, ShowProps, ShowActionsProps, useRecordContext, SelectField } from 'react-admin';
import { INTEGRATION_TYPE_CHOICES } from '../../models/types';

// https://tipsfordev.com/custom-show-form-in-react-admin (custom form and show layout)

const allegroConnect = (id: number) => {
  window.location.href = `${process.env.REACT_APP_API_ENTRYPOINT}/allegro/auth?&id=${id}&redirect=${encodeURIComponent(window.location.href)}`
}

const IntegrationShowActions: React.FC<ShowActionsProps> = () => {
  const record = useRecordContext();
  return <TopToolbar>
      {record && record.id && record.type === 'allegro' ? <Button label="Connect" onClick={() => allegroConnect(record.id as number)}/> : null}
      <EditButton record={record} />
      <ListButton/>
  </TopToolbar>
};

type TruncatedTextFieldProps = {
  source: string,
  addLabel?: boolean,
  length?: number,
}

const TruncatedTextField: React.FC<TruncatedTextFieldProps> = ({source, length = 150}) => {
  const record = useRecordContext();
  if(!record || !record[source]) return <span/>;
  const value = record[source].length > length ? `${record[source].substr(0, length)}...` : record[source]
  return <span>{value}</span>;
}

TruncatedTextField.defaultProps = {
  addLabel: true,
}

const IntegrationShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show actions={<IntegrationShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <SelectField source="type" choices={INTEGRATION_TYPE_CHOICES}/>
      <TextField source="name" />
      <TextField source="status" />
      <TextField source="urlPattern" />
      <TruncatedTextField source="credentials" />
    </SimpleShowLayout>
  </Show>
}

export default IntegrationShow