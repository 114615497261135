import React from 'react'
import { Show, SimpleShowLayout, TextField, NumberField, ShowProps, TitleProps, ChipField } from 'react-admin'
import { MediumImageField } from '../../components/field'
import { DefaultShowActions } from '../../components/form'

const WooProductVariationShowTitle: React.FC<TitleProps> = ({ record }) => {
  return <span>{record ? `${record.id}: ${record.name}` : ''}</span>;
}

const WooProductVariationShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show
      title={<WooProductVariationShowTitle />}
      actions={<DefaultShowActions/>}
      {...props}
    >
    <SimpleShowLayout>
      <TextField source="id" />
      <ChipField source="status" />
      <TextField source="name" />
      <NumberField source="available" />
      <NumberField source="price" options={{style: 'currency', currency: 'PLN'}} />
      <MediumImageField source="image" />
    </SimpleShowLayout>
  </Show>
}

export default WooProductVariationShow