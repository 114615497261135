import React from 'react'
import { ShowActionsProps, TopToolbar, ListButton, EditButton, DeleteButton } from 'react-admin'
import { Grid } from '@mui/material'

type DefaultShowActionsProps = ShowActionsProps & {
    showDelete?: boolean
}

const DefaultShowActions: React.FC<DefaultShowActionsProps> = ({ showDelete = false }) => {
    return <TopToolbar>
        <Grid container>
            <Grid item xs={4}>
                <ListButton label="Back to List"/>
            </Grid>
            <Grid item xs={8} display="flex" justifyContent="flex-end">
                {showDelete ? <DeleteButton mutationMode="pessimistic"/> : null}
                <EditButton/>
            </Grid>
        </Grid>
    </TopToolbar>
}

export default DefaultShowActions