import React from 'react'
import { EditActionsProps, TopToolbar, ShowButton, ListButton } from 'react-admin'
import { Grid } from '@mui/material'

const DefaultEditActions: React.FC<EditActionsProps> = ({ data }) => (
  <TopToolbar>
    <Grid container>
      <Grid item xs={4}>
          <ListButton label="Back to List"/>
      </Grid>
      <Grid item xs={8} display="flex" justifyContent="flex-end">
          <ShowButton/>
      </Grid>
    </Grid>
  </TopToolbar>
)

export default DefaultEditActions