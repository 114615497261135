import React from 'react'
import { Create, CreateProps, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin'
import { DefaultCreateActions } from '../../components/form'
import { offerNameValidator, offerIntegrationValidator, offerIdValidator } from './validation';

const OfferCreate: React.FC<CreateProps> = ({ ...props }) => {
  return <Create actions={<DefaultCreateActions />} redirect="show" {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="sku" />
      <TextInput source="name" validate={offerNameValidator()}/>
      <ReferenceInput label="Integration" source="integration.id" reference="integrations" perPage={100} validate={offerIntegrationValidator()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="quantity" step={1}/>
      <TextInput source="price"/>
      <TextInput source="offerId" validate={offerIdValidator()}/>
      <TextInput source="offerVariantId" label="Variant ID"/>
    </SimpleForm>
  </Create>
}

export default OfferCreate