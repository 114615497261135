import React, { useCallback } from 'react'
import { List, Datagrid, TextField, ListProps, ReferenceField, TextInput, NullableBooleanInput, ReferenceInput, SelectInput, BulkDeleteButton, Button, TopToolbar, useRedirect, NumberField, ShowButton, FunctionField, SelectField, Link, DateField } from 'react-admin'
import { stringify } from 'qs'
import { SmallImageField } from '../../components/field'
import { Offer } from '../../models/types'

const parseQuantity = (value: any): any => {
  if(value === 'true')  return {'min': 1}
  if(value === 'false') return '0'
  return null
}

const formatQuantity = (value: any): any => {
  if(value?.min === 1) return 'true'
  if(value === '0') return 'false'
  return ''
}

export const statusChoices = [
  { id: 1, name: 'Active'},
  { id: 2, name: 'Inative'},
]

export enum OfferType {
  PARENT = 1,
  ITEM = 2,
}

export const typeChoices = [
  { id: OfferType.PARENT, name: 'Parent'},
  { id: OfferType.ITEM, name: 'Item'},
]

const OfferBulkActionButtons: React.FC<any> = ({...props}) => {
  const redirect = useRedirect()
  const handleConnect = useCallback(() => {
    redirect(`/inventories/create?${stringify({offers: props.selectedIds})}`)
  }, [redirect, props.selectedIds])
  return <TopToolbar>
      <Button onClick={handleConnect} label="Connect Offers"/>
      <BulkDeleteButton {...props} mutationMode="pessimistic" />
  </TopToolbar>
}

const parseSearch = (v: string) => v ? `%${v}%` : null
const formatSearch = (v: string) => v != null ? v.replaceAll('%', '') : v

const offerFilters = [
  <TextInput label="Name" source="name" alwaysOn resettable parse={parseSearch} format={formatSearch} />,
  <TextInput label="SKU" source="sku" alwaysOn resettable parse={parseSearch} format={formatSearch} />,
  <ReferenceInput source="integration!id" reference="integrations" alwaysOn>
    <SelectInput label="Integration" optionText="name" />
  </ReferenceInput>,
  <SelectInput label="Status" source="status" alwaysOn choices={statusChoices} resettable/>,
  <SelectInput label="Type" source="type" alwaysOn choices={typeChoices} resettable/>,
  <NullableBooleanInput label="Available" source="quantity" alwaysOn parse={parseQuantity} format={formatQuantity}/>,
]

const OfferList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Offers" perPage={25} filters={offerFilters} hasCreate={false} exporter={false} {...props}>
    <Datagrid bulkActionButtons={<OfferBulkActionButtons/>}>
      <SmallImageField source="image" sortable={false} />
      <FunctionField source="id" render={(record: Offer) => <Link to={`/offers/${record.id}/show`}>{`${record.id}`}</Link>} />
      <FunctionField source="name" render={(record: Offer) => <span>{record.name}<br/><small>{record.sku}</small></span>} />
      <NumberField source="quantity" />
      <FunctionField source="price" render={(record: Offer) => Number(record.price).toFixed(2)} />
      <SelectField source="type" choices={typeChoices}/>
      <SelectField source="status" choices={statusChoices}/>
      <ReferenceField label="Integration" source="integration.id" reference="integrations" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updatedAt" showTime />
      <FunctionField source="offerId" label="Offer (Variant)" render={(record: Offer) => record.url ?
        <><a href={`${record.url}`} rel="noreferrer" target="_blank">{record.offerId}</a><span>{record.offerVariantId ? ` (${record.offerVariantId})` : ''}</span></> :
        <><span>{record.offerId}{record.offerVariantId ? ` (${record.offerVariantId})` : ''}</span></>}
      />
      <>
        <ShowButton label="Show" />
      </>
    </Datagrid>
  </List>
}

export default OfferList
