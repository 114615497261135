export enum Role {
  ROLE_USER       = 'ROLE_USER',
}

export type IntegrationTypeChoice = {
  id: string,
  name: string,
}

export const INTEGRATION_TYPE_CHOICES: IntegrationTypeChoice[] = [
  {id: 'allegro', name: 'Allegro'},
  {id: 'woocommerce', name: 'Woocommerce'},
]

export interface Offer {
  id?: number,
  sku?: string,
  name?: string,
  status?: number,
  type?: number,
  quantity?: number,
  price?: string,
  image?: string,
  offerId?: string,
  offerVariantId?: string,
  url?: string,
  integrationName?: string,
  createdAt: string,
  updatedAt: string,
}

export interface InventoryLink {
  offer?: Pick<Offer, 'id'>
}

export interface Inventory {
  name?: string,
  quantity?: number,
  inventoryLinks?: InventoryLink[],
  status?: number,
}
