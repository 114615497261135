import _ from 'lodash'
import qs from 'qs'
import { fetchUtils, DataProvider, HttpError } from 'ra-core'
import { replaceIri } from './iri'

const _stringify = (params: any): string => qs.stringify(params)

/**
 * Maps react-admin queries to a simple REST API
 * This REST dialect is similar to the one of FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 */
interface HttpQuery {
  page?: any,
  perPage?: any,
  sorting?: any,
  filter?: any
}

interface DataProviderOptions {
  debug?: boolean
}

export interface ApiViolation {
  code: string | null
  message: string
  propertPath: string
}

export interface ApiError {
  type: string
  title: string
  detail: string
  violations: ApiViolation[]
}

const handleError = (e: any) => {
  if(e instanceof HttpError && e.body?.violations) {
    throw new Error((e.body?.violations as ApiViolation[]).map(m => m.message).join(', '))
  }
}

const getTotalItems = (headers: Headers): number => {
  const range = headers.get('content-range')
  if (range == null) return 10
  const rangeArr = range.split('/')
  if (!rangeArr.length) return 10
  const total = rangeArr.pop()
  if(total == null) return 10;
  return parseInt(total, 10)
}

const API_EXT = '.json'

const createDataProvider = (apiHost: string, apiPath: string, httpClient = fetchUtils.fetchJson, options: DataProviderOptions = {}): DataProvider => {
  const apiUrl = `${apiHost}${apiPath}`
  return {
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query: HttpQuery = {
        sorting: { [field]: order }, page, perPage,
      }
      if (!_.isEmpty(params.filter)) {
        query.filter = replaceIri(params.filter, `${apiPath}/${resource}/`)
      }
      options.debug && console.log('getList', resource, query)
      const url = `${apiUrl}/${resource}${API_EXT}?${_stringify(query)}`
      try {
        const { headers, json } = await httpClient(url)
        return {
          data: json,
          total: getTotalItems(headers)
        }
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    getOne: async (resource, params) => {
      const url = `${apiUrl}/${resource}/${replaceIri(params.id)}${API_EXT}`
      options.debug && console.log('getOne', resource, params)
      try {
        const { json } = await httpClient(url)
        return ({
          data: json
        })
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    getMany: async (resource, params) => {
      const query: HttpQuery = { filter: { id: replaceIri(params.ids, `${apiPath}/${resource}/`) } }
      const url = `${apiUrl}/${resource}${API_EXT}?${_stringify(query)}`
      options.debug && console.log('getMany', resource, query)
      try {
        const { json } = await httpClient(url)
        return ({
          data: json,
        })
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    getManyReference: async (resource, params) => {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query: HttpQuery = {
        sorting: { [field]: order }, page, perPage,
        filter: {
          ...params.filter,
          [params.target]: replaceIri(params.id, `${apiPath}/${resource}/`)
        },
      }
      options.debug && console.log('getManyReference', resource, query)
      const url = `${apiUrl}/${resource}${API_EXT}?${_stringify(query)}`
      try {
        const { headers, json } = await httpClient(url)
        return {
          data: json,
          total: getTotalItems(headers)
        }
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    update: async (resource, params) => {
      options.debug && console.log('update', resource, params)
      try {
        const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}${API_EXT}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
        return ({ data: json })
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    updateMany: async (resource, params) => {
      options.debug && console.log('updateMany', resource, params)
      try {
        const responses = await Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })))
        return ({ data: responses.map(({ json }) => json) })
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    create: async (resource, params) => {
      options.debug && console.log('create', resource, params)
      try {
        const { json } = await httpClient(`${apiUrl}/${resource}${API_EXT}`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        })
        return ({
          data: { ...json, id: json.id },
        })
      } catch(e) {
        handleError(e)
        throw e
      }
    },

    delete: async (resource, params) => {
      options.debug && console.log('delete', resource, params)
      try {
        await httpClient(`${apiUrl}/${resource}/${params.id}${API_EXT}`, {
          method: 'DELETE',
        })
        return ({ data: params.previousData! })
      } catch(e: any) {
        handleError(e)
        throw e
      }
    },

    deleteMany: async (resource, params) => {
      options.debug && console.log('deleteMany', resource, params)
      try {
        await Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}${API_EXT}`, { method: 'DELETE' })))
        return ({ data: params.ids })
      } catch(e) {
        handleError(e)
        throw e
      }
    },
  }
}

export default createDataProvider