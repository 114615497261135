import React from 'react'
import { List, Datagrid, TextField, ListProps, FunctionField, TextInput, DateField, ReferenceField } from 'react-admin'
import { Offer } from '../../models'

const offerFilters = [
  <TextInput label="Sku" source="offer.sku" alwaysOn resettable/>,
  <TextInput label="name" source="offer.name" alwaysOn resettable/>,
]

const formatChanges = (record: any) => {
  return <ul>
    {Object
      .keys(record.changes)
      .map((key: string) => <li key={record.id + '_' + key}><b>{`${key}`}</b>: {`${record.changes[key][0]} => ${record.changes[key][1]}`}</li>)
    }
  </ul>
}

const formatOffer = (record: Offer) => {
  return `[${record.integrationName}] ${record.name}`
}

const OfferAuditList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Changes" perPage={25} sort={{ field: 'id', order: 'DESC' }} filters={offerFilters} {...props}>
    <Datagrid>
      <TextField source="id" />
      <DateField source="createdAt" showTime/>
      <ReferenceField label="Offer" source="offer.id" reference="offers" link={"show"}>
        <FunctionField
          source="name"
          render={(record: any) => formatOffer(record)}
        />
      </ReferenceField>
      <FunctionField
        source="changes"
        render={(record: any) => formatChanges(record)}
      />
    </Datagrid>
  </List>
}

export default OfferAuditList
