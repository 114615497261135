import React from 'react'
import { List, Datagrid, TextField, NumberField, TextInput, SelectInput, ListProps, ChipField, ShowButton } from 'react-admin'
import { SmallImageField } from '../../components/field'

const filters = [
  <TextInput label="Name" source="search" alwaysOn resettable />,
  <TextInput label="Sku" source="sku" alwaysOn resettable />,
  <SelectInput label="Status" source="status" alwaysOn resettable choices={[
    { id: 'publish', name: 'Published' },
    { id: 'pending', name: 'Pending' },
    { id: 'private', name: 'Private' },
    { id: 'draft', name: 'Draft' },
  ]} />,
];

const WooProductList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Wordpress Products" perPage={25} filters={filters} filterDefaultValues={{status: 'publish'}} {...props}>
    <Datagrid>
      <SmallImageField source="image" />
      <TextField source="id" />
      <TextField source="sku" />
      <TextField source="name" />
      <NumberField source="available" />
      <NumberField source="price" options={{style: 'currency', currency: 'PLN'}} />
      <ChipField source="type" />
      <ChipField source="status" />
      <>
        <ShowButton label="Show" />
      </>
    </Datagrid>
  </List>
}

export default WooProductList
