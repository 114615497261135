const replaceIri = (filter: object | string | number, prefix?: string): any => {
    //return filter
    //console.log(['replaceIrI', prefix, filter])
    if(typeof filter === 'function') throw new Error('replaceIri() do not accept functions')
    if(typeof filter === 'number') return filter
    if(typeof filter === 'string') {
        if(prefix) return filter.replace(prefix, '')
        return filter.replace(/\D/g, '')
    }
    if(Array.isArray(filter)) return filter.map((iri) => replaceIri(iri, prefix))
    let res: any = {}
    for (const [key, value] of Object.entries(filter)) {
      res[key] = replaceIri(value, prefix);
    }
    return res
  }
  
  function addIri(rec: any, prefix: string): any {
    //return rec
    if(Array.isArray(rec)) return rec.map((r) => addIri(r, prefix))
    rec.id = `${prefix}${rec.id}`
    return rec
  }

  export {
    replaceIri,
    addIri
  }