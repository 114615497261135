import React from 'react'
import { Create, CreateProps, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { INTEGRATION_TYPE_CHOICES } from '../../models/types';

const IntegrationCreate: React.FC<CreateProps> = ({ ...props }) => {
  return <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={INTEGRATION_TYPE_CHOICES}/>
      <TextInput source="name" />
      <TextInput source="credentials" multiline fullWidth />
    </SimpleForm>
  </Create>
}

export default IntegrationCreate