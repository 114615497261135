import React from 'react'
import { Edit, EditProps, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin'
import { DefaultEditActions } from '../../components/form'
import { offerNameValidator, offerIntegrationValidator, offerIdValidator } from './validation';

const OfferEdit: React.FC<EditProps> = ({ ...props }) => {
  return <Edit mutationMode="pessimistic" actions={<DefaultEditActions/>} redirect="show" {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="sku" />
      <TextInput source="name" fullWidth validate={offerNameValidator()}/>
      <ReferenceInput label="Integration" source="integration.id" reference="integrations" perPage={25} validate={offerIntegrationValidator()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="quantity" step={1}/>
      <TextInput source="price"/>
      <TextInput source="offerId" validate={offerIdValidator()}/>
      <TextInput source="offerVariantId" label="Variant ID"/>
    </SimpleForm>
  </Edit>
}

export default OfferEdit