import React from 'react'
import { Show, SimpleShowLayout, TextField, ShowProps } from 'react-admin'
import { DefaultShowActions } from '../../components/form'

const CrawlCheckShow: React.FC<ShowProps> = ({ ...props }) => {
  return <Show actions={<DefaultShowActions showDelete />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
}

export default CrawlCheckShow