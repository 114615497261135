import React from 'react'
import { List, Datagrid, TextField, ListProps, FunctionField, NumberField, SelectInput, TextInput } from 'react-admin'
import { Offer } from '../../models'

const statusChoices = [
  { id: 'Y', name: 'Linked'},
  { id: 'N', name: 'Not Linked'},
];

const offerFilters = [
  <TextInput label="Sku" source="sku" alwaysOn resettable/>,
  <SelectInput label="Status" source="linked" alwaysOn choices={statusChoices} resettable/>,
]

const OfferReportList: React.FC<ListProps> = ({ ...props }) => {
  return <List title="List of Offers" perPage={25} filters={offerFilters} {...props}>
    <Datagrid>
      <TextField source="sku" />
      <NumberField source="delta" />
      <FunctionField
        source="offers"
        render={((record: any) => <ul>{record.offers.map((item: Offer) => <li key={item.id}>{`[${item.quantity}] `}{item.integrationName}: <a href={item.url} rel="noreferrer" target="_blank">{item.name}</a></li>)}</ul>)}
      />
    </Datagrid>
  </List>
}

export default OfferReportList
