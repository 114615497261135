import React from "react"
import { Admin, CustomRoutes, Resource } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import { createHttpClient, createAuthStorage, createAuthProvider, createDataProvider } from './api'
import { Dashboard, Sync, Compare } from './components/page'
import DefaultLayout from './components/DefaultLayout'
import { PermissionCheck } from './api/permission'
import { IntegrationIcon, IntegrationList, IntegrationShow, IntegrationCreate, IntegrationEdit } from './resources/integrations'
import { OfferIcon, OfferList, OfferShow, OfferCreate, OfferEdit } from './resources/offers'
import { OfferReportIcon, OfferReportList } from './resources/offers-report'
import { OfferAuditIcon, OfferAuditList } from './resources/offer-audits'
//import { InventoryIcon, InventoryList, InventoryShow, InventoryCreate, InventoryEdit } from './resources/inventories'
//import { InventoryLinkIcon, InventoryLinkList, InventoryLinkShow, InventoryLinkCreate, InventoryLinkEdit } from './resources/inventory-links'
import { AllegroOfferIcon, AllegroOfferList, AllegroOfferShow } from './resources/allegro-offers'
import { AllegroOrderIcon, AllegroOrderList, AllegroOrderShow } from './resources/allegro-orders'
import { WooProductIcon, WooProductList, WooProductShow } from './resources/woo-products'
import { WooProductVariationIcon, WooProductVariationList, WooProductVariationShow } from './resources/woo-product-variations'
import { CrawlCheckIcon, CrawlCheckList, CrawlCheckShow, CrawlCheckEdit, CrawlCheckCreate } from './resources/crawl-check'

//console.log(process.env)

const API_ENTRYPOINT: string = process.env.REACT_APP_API_ENTRYPOINT as string
const authStorage = createAuthStorage();
const httpClient = createHttpClient(authStorage)
const dataProvider = createDataProvider(API_ENTRYPOINT, '/api', httpClient, { debug: true })
const authProvider = createAuthProvider(API_ENTRYPOINT + '/api', authStorage)

const App = () => (
  <BrowserRouter>
    <Admin
      title="Shop Sync"
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={DefaultLayout}
      disableTelemetry
    >
      {(permissions: PermissionCheck) => [
        <Resource
          name="offers"
          options={{ label: "Offers" }}
          icon={OfferIcon}
          list={OfferList}
          show={OfferShow}
          create={OfferCreate}
          edit={OfferEdit}
        />,
        <Resource
          name="offers-report"
          options={{ label: "Offer Report" }}
          icon={OfferReportIcon}
          list={OfferReportList}
        />,
        <Resource
          name="offer_audits"
          options={{ label: "Offer Audit" }}
          icon={OfferAuditIcon}
          list={OfferAuditList}
        />,
        /*<Resource
          name="inventories"
          options={{ label: "Inventories" }}
          icon={InventoryIcon}
          list={InventoryList}
          show={InventoryShow}
          create={InventoryCreate}
          edit={InventoryEdit}
        />,
        <Resource
          name="inventory_links"
          options={{ label: "Inventory Links" }}
          icon={InventoryLinkIcon}
          list={InventoryLinkList}
          show={InventoryLinkShow}
          create={InventoryLinkCreate}
          edit={InventoryLinkEdit}
        />,*/
        <Resource
          name="integrations"
          options={{ label: "Integrations" }}
          icon={IntegrationIcon}
          list={IntegrationList}
          show={IntegrationShow}
          create={IntegrationCreate}
          edit={IntegrationEdit}
        />,
        <Resource
          name="allegro_offers"
          options={{ label: "Allegro Offers" }}
          icon={AllegroOfferIcon}
          list={AllegroOfferList}
          show={AllegroOfferShow}
        />,
        <Resource
          name="allegro_orders"
          options={{ label: "Allegro Orders" }}
          icon={AllegroOrderIcon}
          list={AllegroOrderList}
          show={AllegroOrderShow}
        />,
        <Resource
          name="woo_products"
          options={{ label: "Woo Products" }}
          icon={WooProductIcon}
          list={WooProductList}
          show={WooProductShow}
        />,
        <Resource
          name="woo_product_variations"
          options={{ label: "Woo Variations" }}
          icon={WooProductVariationIcon}
          list={WooProductVariationList}
          show={WooProductVariationShow}
        />,
        <Resource
          name="crawl_checks"
          options={{ label: "Crawl Checks" }}
          icon={CrawlCheckIcon}
          list={CrawlCheckList}
          show={CrawlCheckShow}
          create={CrawlCheckCreate}
          edit={CrawlCheckEdit}
        />,
        <CustomRoutes>
          <Route path="/sync" element={<Sync/>} />
          <Route path="/compare" element={<Compare/>} />
        </CustomRoutes>
      ]}
    </Admin>
  </BrowserRouter>
);

export default App;
